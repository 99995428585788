import "./index.css";

import Header from "../components/header";
import Helmet from "react-helmet";
import React from "react";
import favicon from "./favicon.ico";
import metaImage from "./meta-img.png";

const TITLE = "Squarecat Web Development";
const DESCRIPTION = "No nonsense web dev by people who love doing it 💛";
const BASE_URL = "https://squarecat.io";
const TWITTER_HANDLE = "@SquarecatWebDev";
const META_IMG_URL = `${BASE_URL}${metaImage}`;

function Layout({ children }) {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>{TITLE}</title>
        <meta name="description" content={DESCRIPTION} />
        <link rel="canonical" href={`${BASE_URL}`} />
        <link rel="shortcut icon" type="image/png" href={favicon} />

        {/* facebook open graph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:image" content={META_IMG_URL} />
        <meta property="og:image:secure_url" content={META_IMG_URL} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={BASE_URL} />
        <meta property="og:title" content={TITLE} />
        <meta property="og:description" content={DESCRIPTION} />
        <meta property="og:site_name" content="Squarecat Web Development" />

        {/* twitter card tags additive with the og: tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:domain" content={BASE_URL} />
        <meta name="twitter:title" content={TITLE} />
        <meta name="twitter:description" content={DESCRIPTION} />
        <meta name="twitter:image" content={META_IMG_URL} />
        <meta name="twitter:url" content={BASE_URL} />
        <meta name="twitter:site" content={TWITTER_HANDLE} />
        <meta name="twitter:label1" content="Get in touch" />
        <meta name="twitter:data1" content="hello@squarecat.io" />
        <html lang="en" />
      </Helmet>
      <Header />
      <main>{children}</main>
    </div>
  );
}

export default Layout;
