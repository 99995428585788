import { Link } from "gatsby";
import React from "react";
import logo from "../../assets/images/logo.png";

const Header = () => (
  <div className="headerino">
    <Link className="go-home-cat" to="/">
      <img src={logo} alt="Logo" className="cat-logo" />
      <h1>Squarecat</h1>
    </Link>
    <nav className="navigates-about-the-things">
      <ul>
        <li>
          <Link activeClassName="active" to="/about">
            <span role="img" aria-label="hug emoji">
              🤗
            </span>{" "}
            About
          </Link>
        </li>
        <li>
          <Link activeClassName="active" to="/work">
            <span role="img" aria-label="space invaders emoji">
              👾
            </span>{" "}
            Work
          </Link>
        </li>
        <li>
          <Link activeClassName="active" to="/apps">
            <span role="img" aria-label="computer emoji">
              🖥
            </span>{" "}
            Apps
          </Link>
        </li>
        <li>
          <Link activeClassName="active" to="/blogs">
            <span role="img" aria-label="pen emoji">
              📝
            </span>{" "}
            Blogs
          </Link>
        </li>
        <li>
          <Link activeClassName="active" to="/contact">
            <span role="img" aria-label="letter emoji">
              💌
            </span>{" "}
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  </div>
);

export default Header;
